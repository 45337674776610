import { Box, Button, TextField, Typography } from "@mui/material";
import { useState } from "react";

export default function PercentOffCalculator() {
  const [originalPrice, setOriginalPrice] = useState<string>("");
  const [percentOff, setPercentOff] = useState<string>("");
  const [finalPrice, setFinalPrice] = useState<string>("");
  const [hasBeenCalculated, setHasBeenCalculated] = useState<boolean>(false);

  const handleCalculation = () => {
    try {
      if (!originalPrice || !percentOff) {
        throw new Error("Please enter a value for both fields");
      }
      const price = parseFloat(originalPrice);
      const percent = parseFloat(percentOff);
      const discount = price * (percent / 100);
      const finalPrice = price - discount;
      setFinalPrice(finalPrice.toFixed(2));
      setHasBeenCalculated(true);
    } catch (err: any) {
      alert(err.message);
    }
  };

  const style = {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
    "& input[type=number]": {
      MozAppearance: "textfield",
    },
  };

  return (
    <Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography sx={{ mt: 10, mb: 5 }} variant="h4">
          Percent Off Calculator
        </Typography>
        <TextField
          sx={{ ...style }}
          value={originalPrice}
          onChange={(e) => setOriginalPrice(e.target.value)}
          label="Original Price"
          variant="filled"
          inputProps={{ inputMode: "decimal" }}
        />
        <TextField
          value={percentOff}
          onChange={(e) => setPercentOff(e.target.value)}
          label="% Off"
          variant="filled"
          inputProps={{ inputMode: "decimal" }}
          sx={{
            mt: 3,
            ...style,
          }}
        />
        <Button onClick={handleCalculation} sx={{ mt: 3 }} variant="contained">
          Calculate
        </Button>
        {hasBeenCalculated && (
          <Typography sx={{ mt: 5 }} variant="h5">
            Final Price: ${finalPrice}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
